
.sectionTitle{
    transform: translateX(10px) translateY(-18px);
    width: fit-content;
    font-size: 12px;
    font-weight: 600;
    background: white;

    padding: 5px;
}
 .slides_active {
    width: 50%;
    border: none;
    background: #023361;
    color: white;
    font-size: 13px;
    height: 40px;
    border-radius: 4px;
    font-weight: bold;
}
.slides_active_comment {
    width: 100%;
    border: none;
    background: #023361;
    color: white;
    font-size: 13px;
    height: 40px;
    border-radius: 4px;
    font-weight: bold;
}

.toggle {
    height: 40px;
    width: 50%;
    border: none;
    background: #e8edf5;
    color: black;
    font-size: 13px;
    border-radius: 4px;
}

.mkt-toggle {
    height: 40px;
    width: 33%;
    border: none;
    background: #e8edf5;
    color: black;
    font-size: 13px;
    border-radius: 0px;
}

.mkt-toggle-active {
    width: 33%;
    border: none;
    background: #023361;
    color: white;
    font-size: 14px;
    height: 40px;
    border-radius: 0px;
    font-weight: bold;
}

.dd-toggle {
    height: 40px;
    width: 33%;
    border: none;
    background: #e8edf5;
    color: black;
    font-size: 14px;
    border-radius: 0px;
}

.dd-toggle-active {
    width: 34%;
    border: none;
    background: #023361;
    color: white;
    font-size: 14px;
    height: 40px;
    border-radius: 0px;
    font-weight: bold;
}

.savings-toggle {
    height: 40px;
    width: 50%;
    border: none;
    background: #e8edf5;
    color: black;
    font-size: 14px;
    border-radius: 0px;
}

.savings-toggle-active {
    width: 50%;
    border: none;
    background: #023361;
    color: white;
    font-size: 14px;
    height: 40px;
    border-radius: 0px;
    font-weight: bold;
}




.table-top {
    padding-top: .5rem !important;
}

.page-title {

    justify-content: space-between;
}

.tab {
    width: 100%;
    margin-top: 10px;
    margin-bottom: -18px;

}

.mkt-tab {
    margin-top: -25px;
    margin-bottom: 20px;
    width: 103% !important;
    margin-left: -7px;

}

.dd-tab {
    margin-top: -25px;
    margin-bottom: 20px;
    width: 102% !important;
    margin-left: -7px;

}

.dossier-table-top-left {
    display: flex;
    /* flex: 0.7;*/
}

.title-text {
    font-size: 14px;
    display: flex;
    color: #023361;
    font-weight: bold;
    flex: 0.6;
}

.sub-title-text {
    display: flex;
    color: #023361;
    font-weight: bold;
    font-size: 12px;
    margin-top: 3px;

}
.mobile-title-text {
    display: flex;
    color: #023361;
    font-weight: bold;
    font-size: 12px;
    margin-top: 3px;

}
.fa {
    display: flex;

}

.block {
    display: inline-block !important;
    margin-left: 0 !important;
}

/* .btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    background-color: #939393;
    color: white;
} */

.btn:hover {
    color: white !important;
}

.savdos {
    font-weight: 700;
    font-size: 12px;
    height: 37px;
    background-color: #02b702;
    color: #fff;
    border: none;
    border-radius: 3px;
    padding: 10px;

    width: 70px;

}

.nextdos {
    font-weight: 700;
    font-size: 12px;
    height: 37px;
    background-color: #4c61f3;
    color: #fff;
    border: none;
    border-radius: 3px;
    padding: 10px;

    width: 70px;

}

.backdos {
    font-weight: 700;
    font-size: 12px;
    height: 37px;
    background-color: #4c61f3;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 10px;

    width: 70px;

}

.requestdos {
    font-weight: 600;
    font-size: 14px;
    height: 37px;
    background-color: #f54a5a;
    color: #fff;
    border: none;
    border-radius: 3px;
    margin-top: 25px;
    width: 100%;
}

.senddos {
    font-weight: 700;
    font-size: 12px;
    height: 37px;
    background-color: #037203;
    color: #fff;
    border: none;
    border-radius: 3px;

    margin-top: 22px;
    width: 100%;
}

.stepperActiveStepLabel {
    color: #0a58ca !important;
    font-weight: bolder;
    font-size: 13px !important;
}

.stepperStepLabel {
    font-size: 13px !important;
}

.stepperStepLabelContainer {
    color: #777777 !important;
}

.stepperCompletedStepLabel {
    font-weight: bolder;
    font-size: 13px !important;
}

.stepperCompletedStepIcon {
    color: #02b702 !important;
    font-size:22px !important;
}

.stepperRequiredStepIcon {
    color: darkorange !important;
    font-size:22px !important;
}

.stepperOptionalStepIcon {
    color: #777777 !important;
    font-size:22px !important;
}

.stepperIconText {
    color: #ffffff !important;
    font-size:14px !important;
}

.complete-btn {
    border: none;
    background-color: #05cf05;
    color: white;
    width: 190px;
    font-size: 12px;
}

.role {
    font-size: 12px;
    font-weight: 500;
}

.complete {
    display: flex;
    justify-content: center;
}

.reject-btn {
    border: none;
    background-color: red;
    color: white;
    width: 190px;
    font-size: 12px;
}

.valid {
    display: flex;
    width: 100%;
    margin-top: 27px;
    margin-bottom: -24px;
}

.status {
    font-size: 12px;
    font-weight: bold;
}

.accept {
    width: 50%;
    height: 36px;
    border-radius: 4px;
    border: none;
    background-color: green;
    color: white;
    font-weight: bold;
    font-size: 12px;
}

.decline {
    width: 50%;
    height: 36px;
    border-radius: 4px;
    border: none;
    background-color: red;
    color: white;
    font-weight: bold;
    font-size: 12px;
}
.chiptitle{
    font-size: 12px;
    border-radius: 5px 0px 0px 5px;
    width: 22%;
    background-color: #b77f01;
    color: white;
}

.chipbody{
    font-size: 12px;
    border-radius: 0px 5px 5px 0px;
    width: 78%;
    background: #dc3545;
    color: white;
    border-color: white;
}
.rdw-editor-wrapper {
    box-sizing: content-box;
    border: 1px;
    border-style: solid;
    border-color: #bdb4b4 !important;
}
.public-DraftStyleDefault-ltr {

    font-size: 12px;}
.slide-disabled{
    color: #023361;
    font-size: 15px;
    text-align: center;
    font-weight: bold;
}

.total{
    margin-left: 15px;
    border: 1px solid #ced4da;
    border-radius: 3px;
    width: 100px;
    height: 30px;
    align-items: center;
    text-align: center;
}