.presentation_details {
    width: 70%;
}

.attendance_details {
    width: 30%;
    margin-top: 5px;
}

.presentation_info {
    width: 80%;

}

.strt {
    margin-top: 82px !important;
}

.attendance_info {
    width: 20%;
    padding-left: 0px;

}

.presentation_tab {
    margin-top: -66px;
    margin-bottom: 20px;
    width: 105% !important;
    margin-left: -23px;

}

.agendaitem:hover {
    background-color: #e8edf5;
}

.agendaitem .tag:hover {
    background-color: #e8edf5;
}

.agendaitem {
    height: 35px;
    width: 25%;
    border: none;
    background: white;
    color: #023361;
    font-size: 12px;
    border-radius: 0px;
    font-weight: bold;
}

.presentation-toggle-active {
    width: 25%;
    border: none;
    background: #023361;
    color: white;
    font-size: 12px;
    height: 35px;
    border-radius: 0px;
    font-weight: bold;
}

.attendance {
    width: 100%;
    border: 1px solid;
    font-weight: 500;
    background: white;
    height: 31px;
    border-color: #e7e7e7;
    text-align: left;
    font-family: sans-serif;
    color: #023361;
    font-size: 13px;
    font-weight: bold;
}

.emptyMeeting {
    color: grey;
    margin-top: 132px;
    text-align: center;
}

.bck {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.nxt {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

ul {
    list-style-type: circle;
}

.meeting_stats {
    background-color: #f3d273;
    color: #121111;
    font-weight: bold;
    font-size: 14px;
}

.meeting_body {
    width: 100%;
    background: rgb(247, 247, 247);
    padding: 10px;

}

.role_header {
    font-family: monospace;
    font-weight: 600;
    color: #023361;
    border-bottom: 1px solid #c5daed;
    background: #f0f6fb;
}

.participant {
    font-family: Niramit;
    color: #023361;
    font-size: 14px;
    font-weight: 500;
}


.online {
    margin-left: -21px;
    margin-top: -13px;
}

.not-started {
    text-align: center;
    font-size: 12px;
    font-style: italic;
    font-weight: 700;
}

.summary {
    font-size: 12px;
}

.user {
    font-size: 14px;
    color: #023361;
    font-weight: bold;
    font-family: Niramit;
}
.participants{
    overflow-y: scroll;

}
.participants::-webkit-scrollbar {
    display: none; /* Width of the scrollbar */
  }
  .participants:hover::-webkit-scrollbar {

    width: 3px; /* Width of the scrollbar */
  }
  .participants::-webkit-scrollbar-thumb {
    background-color: #b6b6b6; /* Color of the thumb (handle) */
  }
  .participants::-webkit-scrollbar-track {
    background-color: #CBD5E0; /* Color of the track (background) */
  }
.ok_vote {
    background-color: green;
    width: fit-content;
    color: white;
    border: 1px solid rgba(216, 216, 216, 0.32);
    box-shadow: rgb(242 242 242) 2px 0px 2px 2px;
    font-size: 12px;
    border-radius: 5px;
    font-weight: bold;
    font-family: 'Segoe UI';
    padding: 5px;
}

.further_vote {
    background-color: #C0B01B;
    width: fit-content;
    color: white;
    border: 1px solid rgba(216, 216, 216, 0.32);
    box-shadow: rgb(242 242 242) 2px 0px 2px 2px;
    font-size: 12px;
    border-radius: 5px;
    font-weight: bold;
    padding: 5px;
    font-family: 'Segoe UI';
}

.abstain_vote {
    background-color: #FD2B3F;
    width: fit-content;
    color: white;
    padding: 5px;
    border: 1px solid rgba(216, 216, 216, 0.32);
    box-shadow: rgb(242 242 242) 2px 0px 2px 2px;
    font-size: 12px;
    border-radius: 5px;
    font-weight: bold;
    font-family: 'Segoe UI';
}

.cmmtbtn {
    color: #0b6799 !important;
    background-color: white !important;
    border: 1px solid #0288d1 !important;
}

.vtn_ongoing {
    float: right;
    background-color: #f54a5a;
    width: fit-content;
    color: white;
    padding: 4px;
    border: 1px solid rgba(216, 216, 216, 0.32);
    box-shadow: rgb(242 242 242) 2px 0px 2px 2px;
    font-size: 12px;
    border-radius: 5px;
    font-weight: bold;
    font-family: Niramit;
}

.presenting {
    background-color: #0288D1;
    width: fit-content;
    color: white;
    padding: 5px;
    border: 1px solid rgba(216, 216, 216, 0.32);
    box-shadow: rgb(242 242 242) 2px 0px 2px 2px;
    font-size: 9px;
    border-radius: 14px;
    font-weight: bold;
    font-family: Niramit;

}

.voting {
    background-color: #0288D1;
    width: fit-content;
    color: white;
    padding: 5px;
    border: 1px solid rgba(216, 216, 216, 0.32);
    box-shadow: rgb(242 242 242) 2px 0px 2px 2px;
    font-size: 9px;
    border-radius: 14px;
    font-weight: bold;
    font-family: Niramit;
}

.approval {
    background-color: #0288D1;
    width: fit-content;
    color: white;
    padding: 5px;
    border: 1px solid rgba(216, 216, 216, 0.32);
    box-shadow: rgb(242 242 242) 2px 0px 2px 2px;
    font-size: 9px;
    border-radius: 14px;
    font-weight: bold;
    font-family: Niramit;
}

.completed {
    background-color: #0ec16e;
    width: fit-content;
    color: white;
    padding: 5px;
    border: 1px solid rgba(216, 216, 216, 0.32);
    box-shadow: rgb(242 242 242) 2px 0px 2px 2px;
    font-size: 9px;
    border-radius: 14px;
    font-weight: bold;
    font-family: Niramit;
}

.null {
    background-color: white;
    width: fit-content;
    color: white;
    padding: 5px;

}

.meeting-title {
    width: 80%;
    border-bottom: 1px solid #f2f2f2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
}

.main_meeting_action_buttons {
    width: 20%;
}

.dossier-title {
    font-size: 14px;
    font-weight: bolder;
    color: #093c58;
}

.requests {
    font-weight: 500;
    font-size: 12px;
    font-style: italic;
}

.main-title {
    font-size: 13px;
    font-weight: bold;
    color: #023361;
}

.mom-richText {
    font-size: 11px !important;
}

.mom-subtitle {
    font-size: 14px;
    font-weight: 500;
}

.mom-dossier-title {
    font-size: 14px;
    font-weight: bold;
    color: #023361;
}
