.agendaDialog {
    left: 760px !important;

}

.agendaDialog .MuiPaper-root {
    overflow-x: hidden;
    width: 500px !important;
}

.sustainabilityMoment input[type="radio"]:checked::before {
    color: green;
}
.my-swal {
    z-index: 1060!important;
  }
.sustainabilityMoment {
    border: 1px solid #d5d0d0;
    width: 48%;
    border-radius: 4px;
    padding: 5px;
}

.agendaRow {
    cursor: move;
}