.center {
    position: absolute;
    top: 25%;
    left: 34%;
    margin-top: -50px;
    margin-left: -50px;

}

.line {
    height: 3px;
    border: 1px solid #2d6499;
    background: #2d6499;
}