.table-res::-webkit-scrollbar {
    display: none !important; /* Width of the scrollbar */
  }
  .table-res:hover::-webkit-scrollbar {
   
    width: 3px !important; /* Width of the scrollbar */
  }
  .table-res::-webkit-scrollbar-thumb {
    background-color: #b6b6b6; /* Color of the thumb (handle) */
  }
  .table-res::-webkit-scrollbar-track {
    background-color: #CBD5E0; /* Color of the track (background) */
  }
  .roles {
    width: 30%;
    float: right
}

.req-details {
    width: 70%;
}
.roles-border {
    border-style: solid;
    border-color: #D9D9D9;
    border-width: 1px;
    border-radius: 4px;

}
.defaultChip{
    background-color: #D9D9D9 !important;
    color: black;
}
.contractor-container{
    border-style: solid;
    border-color: #D9D9D9;
    border-width: 1px;
    border-radius: 3px;
    margin-left: 11px;
}
.inner-roles-border {
    border-style: solid;
    border-color: #D9D9D9;
    border-width: 1px;
    padding: 5px;
}
.roleList:disabled{
    color: black !important;
  }
.rowperpage{
    font-size: 13px;
    width: 100%;
    margin-top: 11px;
}
.req-status{
    margin-bottom: 7px;
}
.submit-btn {
    width: 100%;
}

.error {
    margin-top: 2px;
    font-size: 10px;
    color: red;
}

.rbt-menu>.dropdown-item {
    font-size: 12px !important;
}
.viewdos a{
    color: white !important;
}
.viewdos 
{
    font-weight: 700;
    font-size: 10px;
    height: 37px;
    background-color: #23bf76;

    border: none;
    justify-content: flex-end;
    border-radius: 3px;
    
}
.suggestion-item {
    cursor: pointer;
    padding: 5px;
}

.suggestion-item:hover {
    background-color: lightblue;
}

.savbtn {
    width: 100%;

}

/* .btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    background-color: #e1e1e1;
    color: black;

} */

.title-label {
    font-size: 12px;
    font-weight: 500;
}

.roles-header {
    text-align: center;
    font-weight: bold;
    margin-top: 6px;

}
.related_req_opt{
    border: 1px solid gray;
    border-radius: 5px;
    padding: 5px;
    width: 20%;
}
.fa {

    cursor: pointer;
    margin-top: 4px;

}

.form-control {
    font-size: 14px;
}

.select {

    width: 100%;
    height: 36px;
    border-color: #D9D9D9;
    border-radius: 3px;
}
.currencyselect {

    width: 18%;
    height: 36px;
    border-color: #D9D9D9;
    border-radius: 3px;
    margin-left: 12px;
    margin-right: 3px;
}
.col1 {
    width: 50%;
}

.col2 {
    width: 50%;
}

.relatedrequest input[type="radio"]{
    appearance: none;
    border: 1px solid #d3d3d3;
    width: 16px;
    height: 16px;
    content: none;
    outline: none;
    margin-top: 2px;
    
 }
 
 .relatedrequest input[type="radio"]:checked {
    appearance: none;
    border: 1px solid #d3d3d3;
    width: 16px;
    height: 16px;
    content: none;
    outline: none;
    margin-top: 2px;
 }
 
 .relatedrequest input[type="radio"]:checked::before{
   position: absolute;
   color: green !important;
   content: "\00A0\2713\00A0" !important;
  
   font-weight: bolder;
   font-size: 12px;
 }