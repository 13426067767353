* {
    margin: 0px;

}

.newtable table {
    table-layout: fixed;
    width: 100%;

}

.newtable th {
    width: 95px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.important {
    color: red;
}


body {
    background: #f7f7f7;
    --header-color: #ffffff;
    --body-color: #F9FAFB;
    overflow-x: hidden;
}



/*
.swal2-styled.swal2-confirm {
    background-color: #02b702 !important;
  }*/
.t-label {
    font-size: 10px;
}

div.add {
    border-style: solid;
    border-color: #D9D9D9;
    border-width: 1px;
}

.clsbtn {
    border: none;
    height: 27px;
    font-size: 11px;
    font-weight: bold;
    border-radius: 3px;
    color: white;
    background-color: #dc3545;
    margin-top: 13px;
}

.addcfr {
    border: none;
    height: 30px;
    width: 155px;
    font-size: 11px;
    font-weight: bold;
    border-radius: 3px;
    color: #ffffff;
    background-color: #02b702;
    margin-bottom: 13px;
    float: right;
}

.addbtn {
    border: none;
    height: 27px;
    font-size: 11px;
    font-weight: bold;
    border-radius: 3px;
    color: white;
    background-color: #02b702;
    margin-top: 13px;
}

.back-btn {
    background-color: #E6E6E6;
    color: #585353;
    font-weight: bold;
    border: none;
    width: 80px;
    border-radius: 3px;
}

.next-btn {
    float: right;
    background-color: #2941d1;
    color: #ffffff;
    font-weight: bold;
    border: none;
    width: 80px;
    border-radius: 3px;
}

.column1 {
    width: 33%;
}

.menu-num {
    width: 10%;
    height: 26px;
    background-color: #D9D9D9;
    border: 1px solid #585353;
    border-radius: 50%;
    border: none;
    font-weight: 500;
    text-align: center;
    display: inline-block;
    line-height: 25px;
    font-size: 17px;

}

.menu-desc {
    width: 90%;
}

.ck.ck-editor__editable_inline>:last-child {
    height: 100px;
    overflow-y: scroll;

}

.bar {
    margin-top: 4px;

    right: -51px;
    height: 20px;
    width: 1px;
    background-color: #D9D9D9;
}

.bg-blue {
    background-color: #2941d1;
}

/* Budget upload*/
.file-card {
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px dashed #cbd5e0;
    background-color: #ffffff;
    min-width: 380px;

}

.mobile-file-card {
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px dashed #cbd5e0;
    background-color: #ffffff;
    min-width: 300px;

}

.file-inputs {
    position: relative;

}

.upload-input {
    position: relative;
    text-align: right;
    opacity: 0;
    z-index: 2;
    left: 53px;
    cursor: pointer;

    width: 68%;
    height: 100%;
    top: 22px;
}

.upload-btn {
    top: -9px;
    left: 53px;
    width: 68%;
    height: 100%;
    z-index: 1;
}


.css-6pxto6-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    font-size: 12px;
}

.main {
    font-weight: bold;
    margin-bottom: 0.4em;
}

.info {
    font-size: 0.8rem;
}

.files {
    list-style: none;
    margin: 1.2em 0;
    background-color: #edf2f7;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 1.2em 1.5em;
    justify-content: space-between;

}

.box {
    border: 1px solid #0075b0;
}

.boxTitle {
    color: #0075b0;
    font-weight: bold;
    border: 1px solid #0075b0;
    padding: 5px;
}

.file-name {
    width: 60%;
    font-weight: 700;
}

.mobile-file-name {
    width: 60%;
    font-size: 12px;
    font-weight: 600;
}

.del-file {
    display: flex;
    width: 20%;
    justify-content: flex-end;
}

.delfilebtn {
    background: #dc3545;
    ;
    color: white;
}

.downloadbtn {
    background: #0f940f;
    color: white;
}

.dld-file {
    display: flex;
    width: 20%;
    justify-content: flex-end;
}

.react-select-disabled {
    background-color: #e9ecef !important;
    color: #212529 !important;
    border-color: #cccccc !important;
    -webkit-text-fill-color: #212529 !important;
}

input:disabled {
    color: #212529 !important;
    -webkit-text-fill-color: #212529 !important;
}

.businessareaOptions .css-1cxenu,
.css-1yhbstl-MuiButtonBase-root-MuiChip-root {
    background-color: #e9ecef !important;
    color: #212529 !important;
}

.businessareaOptions.css-1lviy0e-MuiButtonBase-root-MuiChip-root {
    background-color: #e9ecef !important;
    color: #212529 !important;
}

.add-height {
    padding-bottom: 6.5rem !important;
}

.css-rqa15j-MuiButtonBase-root-MuiButton-root.Mui-disabled {
    background-color: #D9D9D9 !important;
}


.swal2-popup {
    width: 24em;
}


.swal2-container {
    z-index: 20000 !important;
}

.sideheader {
    font-size: 12px;
    background: #f5f5f9 !important;
    width: 30%;
    font-weight: 600;

}



.mainbody {
    width: 100%;
}

.mobile-sidebar {
    width: 9%;
}

.mobile-mainbody {
    width: 100%;
}

.mobile-page-title {
    margin-top: 65px !important;
    background: #fff;
    border-bottom: 1px solid #f2f2f2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    margin: 5px;
    padding-top: 3px;
    padding-left: 3px;
}

.content-desktop {
    display: block;
}

.content-mobile {
    display: none;
}

@media screen and (max-width: 768px) {

    .content-desktop {
        display: none;
    }

    .content-mobile {
        display: block;
    }

    .col2 {
        width: 100% !important;
    }

    .col1 {
        width: 100% !important;
    }

    .column1 {
        width: 100% !important;
    }

    .title-text {
        font-size: 13px;
    }

    .abstain_vote {
        font-size: 11px;
    }

    .ok_vote {
        font-size: 11px;
    }

    .further_vote {
        font-size: 11px;
    }

    .dossier_stepper {
        display: none;
    }

    .dossier_mobile {
        display: block;
    }

    .attendance_info {
        display: none;
    }

    .presentation_info {
        width: 100% !important;
    }
}