.fixed-top-nav {
  background: rgb(255, 255, 255);
  border-bottom: 1px solid rgba(216, 216, 216, 0.32);
  box-shadow: rgb(242 242 242) 2px 0px 2px 2px;
  position: fixed;
  right: 0;
  left: 0px;
  z-index: 1000;
  top: -12px;
}

.dropdown-menu {
  right: 5;
  width: 185px !important;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
  margin-left: -47px;
  width: 50px;
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(2 51 97);
}

.inappname {
  font-size: 15px;
  padding-left: 17px;
  padding-top: 20px;
  color: #023361;
  text-shadow: -1px 0px 1px #0000002e;
}

.badge {
  margin-top: 10px;
  background-color: #0768bb;
  ;
  width: fit-content;
  color: white;
  font-size: 10px;
  border-radius: 5px;
  font-weight: bold;
  font-family: Niramit;
  padding: 5px;
  height: fit-content;
}

.meetingheader {

  background: rgb(255, 255, 255);
  border-bottom: 1px solid rgba(216, 216, 216, 0.32);
  box-shadow: rgb(242 242 242) 2px 0px 2px 2px;
  position: fixed;

  z-index: 1000;
  top: -12px;
  width: 100%;
}

.meeting_logo {

  height: 45px;

  margin-top: 15px;
}

.mobile_logo {
  height: 27px;
  margin-top: 15px;
  margin-left: -11px;
}

.mobile_header_text {
  margin-top: 17px;
  margin-left: -12px;
  font-size: 15px;
  color: #023361;
  text-shadow: -1px 0px 1px #0000002e;
}