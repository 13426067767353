button {
  font-size: inherit;
  font-family: inherit;
}

.error-msg {
  color: hsl(0, 100%, 67%);
}

.comments-title {
  margin-bottom: .5rem;
}

.comment-form-row {
  /*display: flex;*/
  gap: .5rem;
}

.message-input {
  flex-grow: 1;
  resize: none;
  height: 72px;
  border-radius: 5px;
  padding: .5em;
  font-size: inherit;
  font-family: inherit;
  border: 2px solid hsl(235, 50%, 74%);
  line-height: 1.4;
  width: 100%;
  margin-bottom: 7px;
}

.message-input:focus {
  border-color: hsl(235, 100%, 67%);
  outline: none;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-1 {
  margin-top: .25rem;
}

.comment-stack {
  margin: .5rem 0;
}

.comment-stack:last-child {
  margin-bottom: 0;
}

.nested-comments {
  padding-left: .5rem;
  flex-grow: 1;
}

.nested-comments-stack {
  display: flex;
}

.comment-text {
  font-size: 13px;
}

.collapse-line {
  border: none;
  background: none;
  padding: 0;
  width: 15px;
  margin-top: .5rem;
  position: relative;
  cursor: pointer;
  outline: none;
  transform: translateX(-50%);
}

.collapse-line:hover::before,
.collapse-line:focus-visible::before {
  background-color: hsl(235, 100%, 60%);
}

.collapse-line::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 1px;
  background-color: hsl(235, 50%, 74%);
  transition: background-color 100ms ease-in-out;
}

.date {
  font-size: 11px;
  color: rgb(99, 95, 95) !important;
  width: 40%;
  text-align: right;
}

a .comment-name {
  color: rgb(99, 95, 95) !important;
}

a:link .comment-name:hover {
  background-color: white !important;
}



.hide {
  display: none;
}

.init {
  display: flex;
}

.initbox {
  box-shadow: 0 2px 8px rgb(0 0 0 / 12%);
  height: 33px;
  background-color: #d5deeb;
  margin-top: 9px;
  color: #01204c;
  text-align: center;
  font-size: 19px;
  border-radius: 3px;
  font-weight: 600;
  /* justify-content: center; */
  width: 44px;
}

.comment-refresh {
  background-color: white;
}



.comment-single {
  padding: .5rem !important;
  box-shadow: 0 2px 8px rgb(0 0 0 / 12%);
  height: fit-content;
  background-color: white;
  width: 100%;
  border-radius: 5px;
}

.comment-single-reply {
  box-shadow: 0 2px 8px rgb(0 0 0 / 12%);
  padding-bottom: .5rem;
  background-color: white;
  width: 100%;
  border-radius: 5px;
}

.comment-single-reply-child {
  margin-left: 30px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 12%);
  padding-bottom: .5rem;
  background-color: white;
  width: 90%;
  border-radius: 5px;
}

.initbox-cmt {
  box-shadow: 0 2px 8px rgb(0 0 0 / 12%);
  height: 20px;
  background-color: #d5deeb;
  /* margin-top: 9px; */
  color: #01204c;
  text-align: center;
  font-size: 12px;
  border-radius: 3px;
  font-weight: 600;
  /* justify-content: center; */
  width: 30px;

}

.comment-actions {
  display: flex;
  font-size: 12px;
  color: rgb(51, 51, 51);
  cursor: pointer;
  margin-top: 8px;
  float: right;
}

.comment-action {
  margin-right: 8px;
}

.cmt-btn {

  padding: .5em 1em;
  float: right;
  background: #02b702;
  border: none;
  color: white;
  border-radius: .5em;
  font-size: .75em;
  cursor: pointer;
}

.close {
  float: right;
  margin-top: -25px;
}

.editcmt-btn,
.edit1cmt-btn {
  padding: .5em 1em;

  background: black;
  border: none;
  color: white;
  border-radius: .5em;
  font-size: .75em;
  cursor: pointer;
}

.delcmt-btn,
.del1cmt-btn {
  padding: .5em 1em;

  background: red;
  border: none;
  color: white;
  border-radius: .5em;
  font-size: .75em;
  cursor: pointer;
}

.replycmt-btn,
.reply1cmt-btn {
  padding: .5em 1em;

  background: #02b702;
  border: none;
  color: white;
  border-radius: .5em;
  font-size: .75em;
  cursor: pointer;
}

.cmt-btn:hover,
.cmt-btn:focus-visible {
  --color: hsl(var(--hue), 100%, 74%);
}

.btn.icon-btn {
  background: none;
  color: var(--color);
  padding: .25em;
  display: flex;
  align-items: center;
}

.mr-1 {
  margin-right: .25em;
}

.icon-btn:hover,
.icon-btn:focus-visible {
  --color: hsl(var(--hue), 100%, 74%);
}

.icon-btn-active,
.icon-btn.danger {
  --hue: 0;
}

.icon-btn-active {
  position: relative;
}

.icon-btn-active::before {
  content: "\00D7";
  position: absolute;
  font-size: .75em;
  width: 1em;
  height: 1em;
  color: white;
  background-color: var(--color);
  border-radius: 50%;
  bottom: .1em;
  right: .1em;
}

.comment {
  padding: .5rem;
  border: 1px solid hsl(235, 100%, 90%);
  border-radius: .5rem;
}

.comment .header {
  color: hsl(235, 50%, 67%);
  display: flex;
  justify-content: space-between;
  margin-bottom: .25rem;
  font-size: .75em;
}

.comment .header .name {
  font-weight: bold;
}

.comment .message {
  white-space: pre-wrap;
  margin-left: .5rem;
  margin-right: .5rem;
}

.comment .footer {
  display: flex;
  gap: .5rem;
  margin-top: .5rem;
}

.ml-3 {
  margin-left: 1.5rem;
}

.btn[disabled] {
  --color: hsl(var(--hue), 20%, 74%);
}


.comment-feed {
padding: 5px;
  overflow-y: scroll;

}

.comment-feed::-webkit-scrollbar {
  display: none;
  /* Width of the scrollbar */
}

.comment-feed:hover::-webkit-scrollbar {

  width: 3px;
  /* Width of the scrollbar */
}

.comment-feed::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
  /* Color of the thumb (handle) */
}

.comment-feed::-webkit-scrollbar-track {
  background-color: #CBD5E0;
  /* Color of the track (background) */
}