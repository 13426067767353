.side-nav-wrap {
    width: 100px;
    background: rgb(255, 255, 255);
    height: 100%;
    border-right: 1px solid rgba(216, 216, 216, 0.32);
    padding: 0px;
    position: fixed;
  }
  
  .logo {
    margin-left: 3px;
    height: 29px;
    margin-top: 12px;
  }
  
  .bottomlogo {
    margin-left: 15px;
    width: 50px;
    height: calc(100% - 90%);
    background-color: white
  }
  
  
  .sidebar-options {
  
    margin-top: 8px;
    font-size: 12px;
  }
  
  .fa {
    display: inline-block !important;
  }
  
  .icon-card {
    width: 35px;
    height: 35px;
    background: #F5F3F3;
    border-radius: 4px;
    margin: auto;
    text-align: center;
  
  }
  
  .side-nav-area ul li a.isactive .sidebar-options .icon-card {
    width: 35px;
    height: 35px;
    background: #023361 !important;
    border-radius: 4px;
    margin: auto;
    color: white !important;
    text-align: center;
  
  }
  
  
  .side-nav-area ul li a.isactive {
    background-color: #f3f3f3 !important;
    color: #023361;
    font-weight: 400;
    font-size: 14px;
    height: 70px;
    width: 100% !important;
    border-radius: 0px;
    margin: auto;
  
  }
  
  /* .side-nav-area ul li:hover {
    background: rgb(7 38 199 / 5%);
    transition: all 1s ease;
    border-radius: 4px;
    height: 66px;
    width: 70% !important;
    border-radius: 4px;
    margin: auto;
  }
    */
  
  .side-nav-area {
    text-align: center;
   

  
  }
  .side-nav-area::-webkit-scrollbar {
    display: none; /* Width of the scrollbar */
  }
  .side-nav-area:hover::-webkit-scrollbar {
   
    width: 3px; /* Width of the scrollbar */
  }
  .side-nav-area::-webkit-scrollbar-thumb {
    background-color: #b6b6b6; /* Color of the thumb (handle) */
  }
  .side-nav-area::-webkit-scrollbar-track {
    background-color: #CBD5E0; /* Color of the track (background) */
  }
  
  .side-nav-area ul {
    padding: 0;
  
  }
  
  a:link {
    text-decoration: none !important;
    width: 100%;
    font-weight: 500;
  
  }
  
  a:link :hover {
    background-color: none;
  }
  
  .side-nav-area ul li a {
    color: #023361;
  }
  
  .side-nav-area ul li {
    display: flex;
    background-color: white;
    text-decoration: none !important;
  
    margin: 0.2rem 0;
    cursor: pointer;
  
  }